const Edit: React.FC<{width?: number, height?: number}> = ({
  width = 12,
  height = 12,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 348 348"
    fill="none"
  >
    <path
      d="M246.488 13.3748L213.684 46.1788L301.794 134.289L334.598 101.485C351.542 84.5405 351.542 57.0909 334.598 40.1467L307.894 13.3748C290.95 -3.56936 263.5 -3.56936 246.556 13.3748H246.488ZM213.684 46.1788L40.3791 219.552C33.3303 226.601 28.1793 235.344 25.3327 244.9L1.3397 326.436C-0.354723 332.197 1.20414 338.365 5.4063 342.567C9.60846 346.769 15.7761 348.328 21.4694 346.701L103.005 322.708C112.561 319.861 121.305 314.71 128.353 307.662L301.794 134.289L213.684 46.1788Z"
      fill="currentColor"
    />
  </svg>
);

export default Edit;
