"use client";

import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "./notification-group";
import { notificationVariants } from "./notification.styles";
import { useNotification } from "./useNotification";

export const Notification: React.FC = () => {
  const { notifications } = useNotification();

  return (
    <ToastProvider>
      {notifications.map(function ({
        id,
        title,
        description,
        action,
        kind,
        ...props
      }) {
        const { contentContainer, icon } = notificationVariants({ kind });

        return (
          <Toast key={id} {...props}>
            <div className={contentContainer()}>
              <div className="mr-[6px] flex flex-1 items-baseline gap-3">
                <i className={icon()} />
                <div>
                  {title && <ToastTitle>{title}</ToastTitle>}
                  {description && (
                    <ToastDescription>{description}</ToastDescription>
                  )}
                  {!!action && (
                    <div
                      className="cursor-pointer pt-2"
                      onClick={action.onClick}
                      aria-label="notification-action"
                      role="button"
                    >
                      <span className="text-sm leading-6 text-brand-primary-rest underline">
                        {action.label}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <ToastClose />
            </div>
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
};
