import { NotificationKind } from "~/scalis-components/core/notification";

export const errorHandler = (toast?: any, error?: any) => {
  const message =
    typeof error === "string" ? error : error?.response?.data?.message;
  if (toast) {
    toast?.({
      title: "Error - something went wrong",
      description: message ?? "Please try again later",
      variant: "destructive",
    });
  }
  console.error(error);
};

export const notificationErrorHandler = ({
  notification,
  responseError,
  mesageError,
}: {
  notification?: any;
  responseError?: any;
  mesageError?: string;
}) => {
  const message = mesageError ?? responseError?.response?.data?.message;
  if (notification) {
    notification?.({
      title: "Error - something went wrong",
      description: message ?? "Please try again later",
      kind: NotificationKind.danger,
    });
  }
};
