const Strikethrough = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 9.37489H11.2225C10.945 9.30027 10.6662 9.23047 10.3863 9.16552C8.63125 8.75052 7.63875 8.44677 7.63875 7.02614C7.6245 6.7809 7.66081 6.53535 7.74542 6.30473C7.83004 6.07411 7.96115 5.86335 8.13062 5.68552C8.6615 5.24896 9.32644 5.0084 10.0137 5.00427C11.7825 4.96052 12.5981 5.56052 13.265 6.47302L14.2744 5.73552C13.8019 5.05699 13.1578 4.51605 12.4078 4.16796C11.6578 3.81987 10.8288 3.67711 10.0056 3.75427C8.99439 3.76072 8.01887 4.12898 7.25563 4.79239C6.96634 5.08583 6.74024 5.43541 6.59125 5.81959C6.44227 6.20377 6.37356 6.61439 6.38937 7.02614C6.36197 7.4767 6.4466 7.92702 6.63572 8.33688C6.82483 8.74674 7.11254 9.10337 7.47312 9.37489H2.5V10.6249H11.0325C12.2619 10.9811 12.9969 11.4449 13.0156 12.7236C13.0359 12.9968 12.9985 13.2712 12.9056 13.5289C12.8128 13.7866 12.6667 14.0218 12.4769 14.2193C11.8155 14.7406 10.9938 15.0165 10.1519 14.9999C9.52345 14.9817 8.90738 14.8208 8.35029 14.5294C7.7932 14.2381 7.30966 13.8238 6.93625 13.318L5.97812 14.1205C6.46358 14.7675 7.08994 15.2954 7.80972 15.6643C8.52951 16.0333 9.32384 16.2335 10.1325 16.2499H10.195C11.3492 16.2632 12.4695 15.8595 13.35 15.113C13.6625 14.7979 13.9054 14.4208 14.0632 14.006C14.2209 13.5913 14.2898 13.148 14.2656 12.7049C14.289 11.9469 14.0332 11.2068 13.5469 10.6249H17.5V9.37489Z"
      fill="currentColor"
    />
  </svg>
);

export default Strikethrough;
