const UnorderedList = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.375 9.5C7.41053 9.5 8.25 8.66053 8.25 7.625C8.25 6.58947 7.41053 5.75 6.375 5.75C5.33947 5.75 4.5 6.58947 4.5 7.625C4.5 8.66053 5.33947 9.5 6.375 9.5Z"
        fill="currentColor"
      />
      <path
        d="M6.375 18.25C7.41053 18.25 8.25 17.4105 8.25 16.375C8.25 15.3395 7.41053 14.5 6.375 14.5C5.33947 14.5 4.5 15.3395 4.5 16.375C4.5 17.4105 5.33947 18.25 6.375 18.25Z"
        fill="currentColor"
      />
      <path
        d="M12 15.75H20.75V17H12V15.75ZM12 7H20.75V8.25H12V7Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default UnorderedList;
