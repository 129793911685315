export enum NotificationKind {
  warning = "warning",
  danger = "danger",
  success = "success",
  info = "info",
}

interface Action {
  label: string;
  onClick: () => void;
}

export type NotificationProps = {
  id: string;
  title: string;
  description?: string;
  action?: Action;
  kind: NotificationKind;
};
