import { $createParagraphNode, $getRoot, LexicalEditor } from "lexical";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";

import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import ExampleTheme from "./config";
import ToolbarPlugin from "./toolbar";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { cn } from "~/utils/cn";
import { Divider } from "~/scalis-components/core/divider";

const editorConfig = {
  namespace: "Scalis",
  theme: ExampleTheme,
  onError(error: unknown) {
    throw error;
  },
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
  ],
};

interface IEditor {
  initialContent?: string;
  setValue: Dispatch<SetStateAction<string>>;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  contentClassName?: string;
  placeholderClassName?: string;
  hidePlaceHolderOnFocus?: boolean;
  toolbarDivider?: boolean;
  value?: string;
}

export const Editor = ({
  initialContent,
  setValue,
  className,
  disabled = false,
  placeholder,
  autoFocus = true,
  contentClassName,
  placeholderClassName,
  hidePlaceHolderOnFocus = false,
  toolbarDivider = false,
  value,
}: IEditor) => {
  const usedValueProps = value != undefined;
  const isMounted = useRef(false);
  const [focused, setFocused] = useState(false);

  const onChange = (editor: LexicalEditor) => {
    editor.update(() => {
      const root = $getRoot();

      if (!isMounted.current && usedValueProps) {
        root.clear();
        isMounted.current = true;
      }

      const firstChild = root.getFirstChild();
      if (!firstChild && usedValueProps) {
        setValue("");
        return;
      }

      const htmlString = $generateHtmlFromNodes(editor, null);

      setValue(htmlString);
    });
  };

  return (
    <LexicalComposer initialConfig={{ ...editorConfig, editable: !disabled }}>
      <div
        className={cn(
          "rounded-lg border border-borders-borders p-2",
          { "opacity-50": disabled },
          className,
        )}
      >
        <div>
          <ToolbarPlugin disabled={disabled} />
          {toolbarDivider && <Divider />}
        </div>
        <div className="relative">
          <RichTextPlugin
            placeholder={
              <div
                className={cn(
                  "pointer-events-none absolute left-2.5 top-4 inline-block select-none font-normal text-typography-low-contrast",
                  { invisible: hidePlaceHolderOnFocus && focused },
                  placeholderClassName,
                )}
              >
                {placeholder}
              </div>
            }
            contentEditable={
              <ContentEditable
                className={cn(
                  "h-[300px] overflow-y-scroll p-3 text-sm outline-none",
                  contentClassName,
                )}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
              />
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <LoadInitialContent initialContent={initialContent} />
          <HistoryPlugin />
          {autoFocus && <AutoFocusPlugin />}
          <ListPlugin />
          <LinkPlugin />

          <OnChangePlugin
            onChange={(editorState, editor) => onChange(editor)}
          />
          {usedValueProps && <ClearContent value={value} />}
          <ControlDisabled disabled={disabled} />
        </div>
      </div>
    </LexicalComposer>
  );
};

type Props = { initialContent?: string };

export const LoadInitialContent = ({ initialContent }: Props) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!initialContent) return;
    editor.update(() => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(initialContent, "text/html");

      const nodes = $generateNodesFromDOM(editor, dom);

      $getRoot().clear();
      nodes.forEach(n => {
        if (n.__type !== "paragraph") {
          const paragraph = $createParagraphNode();
          paragraph.append(n);
          return $getRoot().append(paragraph);
        }
        return $getRoot().append(n);
      });
    });
  }, []);

  return null;
};

interface ClearContentProps {
  value: string;
}
export const ClearContent = ({ value }: ClearContentProps) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const editorEmptyState = '<p class="m-0 mb-2 relative"><br></p>';
    if (!value || value === editorEmptyState) {
      editor.update(() => {
        $getRoot().clear();
      });
    }
  }, [value]);

  return null;
};

interface ControlDisabledProps {
  disabled: boolean;
}
export const ControlDisabled = ({ disabled }: ControlDisabledProps) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.setEditable(!disabled);
  }, [disabled]);

  return null;
};
