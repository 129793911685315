const exampleTheme = {
  ltr: "text-left",
  rtl: "text-right",
  placeholder:
    "overflow-hidden absolute text-ellipsis top-4 left-2.5 select-none inline-block pointer-events-none hidden",
  paragraph: "m-0 mb-2 relative",
  quote: "m-0 ml-5 pl-4",
  heading: {
    h1: "text-2xl",
    h2: "font-bold",
    h3: "",
    h4: "",
    h5: "",
  },
  list: {
    nested: {
      listitem: "list-none",
    },
    ol: "m-0 ml-4 p-0 list-decimal list-inside",
    ul: "m-0 ml-4 p-0 list-disc list-inside",
    listitem: "px-8 py-2",
  },
  image: "",
  link: "text-primary",
  text: {
    bold: "font-bold",
    italic: "italic",
    overflowed: "",
    hashtag: "",
    underline: "underline",
    strikethrough: "line-through",
    underlineStrikethrough: "line-through underline",
  },
};

export default exampleTheme;
