const OrderedList = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 15.75H20.75V17H12V15.75ZM12 7H20.75V8.25H12V7ZM7 9.5V4.5H5.75V5.125H4.5V6.375H5.75V9.5H4.5V10.75H8.25V9.5H7ZM8.25 19.5H4.5V17C4.5 16.6685 4.6317 16.3505 4.86612 16.1161C5.10054 15.8817 5.41848 15.75 5.75 15.75H7V14.5H4.5V13.25H7C7.33152 13.25 7.64946 13.3817 7.88388 13.6161C8.1183 13.8505 8.25 14.1685 8.25 14.5V15.75C8.25 16.0815 8.1183 16.3995 7.88388 16.6339C7.64946 16.8683 7.33152 17 7 17H5.75V18.25H8.25V19.5Z"
      fill="currentColor"
    />
  </svg>
);

export default OrderedList;
